import { shouldRefreshToken, syncRefreshToken } from '@core/utils/shouldRefreshToken';
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import Cookies from 'js-cookie';

export const axiosBaseHeader: AxiosRequestHeaders = {
  'x-version': '2',
  'x-platform': process.env.APP_PLATFORM
};

export const axiosBaseConfigOptions: AxiosRequestConfig = {
  timeout: 30000,
  headers: axiosBaseHeader
};

export const axiosInterceptorRequest = async (headerConfig: AxiosRequestConfig<unknown>) => {
  const access_token_cookie =
    Cookies.get('access_token') ?? (await localStorage.getItem('access_token'));
  const refresh_token =
    Cookies.get('refresh_token') ?? (await localStorage.getItem('refresh_token'));

  const isShouldRefreshToken = shouldRefreshToken();

  if (access_token_cookie && headerConfig.headers) {
    headerConfig.headers.Authorization = `Bearer ${access_token_cookie}`;
  } else if (refresh_token && isShouldRefreshToken) {
    const { access_token, error } = await syncRefreshToken();
    if (!error && access_token && headerConfig.headers) {
      headerConfig.headers.Authorization = `Bearer ${access_token}`;
    }
  }

  headerConfig.headers = {
    ...headerConfig.headers,
    'x-platform': process.env.APP_PLATFORM
  };

  return headerConfig;
};

export const axiosInterceptorResponseError = async ({
  error,
  instance
}: {
  error: AxiosError;
  instance: AxiosInstance;
}) => {
  const status = error.response ? error.response.status : null;
  const errorConfig = error.config;

  if (status === 403 || status === 406 || status === 401 || status === 500) {
    syncRefreshToken().then(({ access_token, error }) => {
      if (!error && access_token && errorConfig.headers) {
        errorConfig.headers.Authorization = `Bearer ${access_token}`;
        return instance.request(errorConfig);
      }
    });
  }
  return Promise.reject(error);
};
