import { createContext, useState } from 'react';

interface CompanyDataInterface {
  id: string;
}

interface CompanyContextInterface {
  company: CompanyDataInterface | undefined;
  setCompany: (value: CompanyDataInterface | undefined) => void;
}

export const CompanyContext = createContext<CompanyContextInterface>({
  company: undefined,
  setCompany: () => undefined
});

export const CompanyContextProvider = CompanyContext.Provider;
export const CompanyContextConsumer = CompanyContext.Consumer;
CompanyContext.displayName = 'User Me Context';

export const AppCompanyContext = ({ children }: { children: React.ReactNode }) => {
  const [company, setCompany] = useState<CompanyDataInterface>();
  return (
    <CompanyContextProvider
      value={{
        company,
        setCompany
      }}
    >
      {children}
    </CompanyContextProvider>
  );
};
