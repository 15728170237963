import { getUserMe } from '@api/GET_Me';
import { postUserLogin } from '@api/POST_Login';
import { appCookies } from '@core/utils/appCookies';
import { isAxiosError } from '@core/utils/errorUtils';
import { localStorageService } from '@core/utils/localStorage';
import { ColorRed, LoadingIndicatorBox } from '@uangcermat/uikit-web';
import { epochFormatter } from '@utils/epochTimeFormatter';
import {
  DataMeInterface,
  LanguageType,
  PostBodyLoginInterface,
  UserCompaniesInterface
} from 'interface/UserInterface';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { getUserLogout } from '../api/GET_Logout';

interface AuthContextInterface {
  isAuthenticated: boolean;
  isLoading: boolean;
  userAuth: DataMeInterface | null;
  errorMessage: string | null;
  login: (value: PostBodyLoginInterface) => void;
  logout: () => void;
  setUserAuth: (value: DataMeInterface) => void;
  setIsResetCompany: (value: boolean) => void;
  setActiveCompany: (company: UserCompaniesInterface) => void;
  setErrorMessage: (message: null | string) => void;
  isResetCompany: boolean;
  activeCompany: UserCompaniesInterface | null;
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  isLoading: true,
  userAuth: null,
  errorMessage: null,
  login: () => undefined,
  logout: () => undefined,
  setUserAuth: () => undefined,
  setActiveCompany: () => undefined,
  setErrorMessage: () => undefined,
  setIsResetCompany: () => undefined,
  activeCompany: null,
  isResetCompany: false
});

const isRedirectedToDashboard = (pathname: string): boolean => {
  return ['/', '/login'].includes(pathname);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userAuth, setUserAuth] = useState<DataMeInterface | null>(null);
  const [activeCompany, setActiveCompany] = useState<UserCompaniesInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isResetCompany, setIsResetCompany] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const { setCookie, removeCookie } = appCookies();

  const router = useRouter();

  const capitalizeName = ({ name }: { name: string }) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchUserProfile = useCallback(async () => {
    if (!userAuth) {
      const { data: userData, error } = await getUserMe();
      if (!error && userData) {
        const { name } = userData;
        const newName = capitalizeName({ name: name });
        setUserAuth({
          ...userData,
          name: newName
        });
        const activeCompany =
          (localStorageService.getActiveCompany('active_company') as string) ?? '';
        if (userData.companies.length > 0) {
          const findIndexCompany = userData.companies.findIndex(
            (company: UserCompaniesInterface) => company.id === activeCompany
          );
          if (findIndexCompany >= 0) {
            setActiveCompany(userData.companies[findIndexCompany]);
          } else {
            setActiveCompany(userData.companies[0]);
          }
        }
      }
      if (isRedirectedToDashboard(router.pathname)) {
        if (userData.companies && userData.companies.length > 1) {
          router.push('/switch-company');
        } else {
          router.push('/activity/report');
        }
      }
      setIsLoading(false);
    }
  }, [router, userAuth]);

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get('access_token');
      const refreshToken = Cookies.get('refresh_token');
      if (token) {
        if (!userAuth) {
          fetchUserProfile();
        }
      } else {
        if (refreshToken) {
          fetchUserProfile();
        } else {
          setIsLoading(false);
          if (router.pathname !== '/login') {
            router.push('/login');
          }
        }
      }
    }
    loadUserFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserProfile]);

  const login = async ({
    email,
    password,
    lang
  }: {
    email: string;
    password: string;
    lang: LanguageType;
  }) => {
    try {
      const { access_token, error, expire_token, message, refresh_token } = await postUserLogin({
        email,
        password,
        lang
      });

      setIsLoading(true);

      if (!error && access_token && !userAuth) {
        setCookie({
          name: 'access_token',
          value: access_token,
          options: { expires: expire_token ? epochFormatter(expire_token) : undefined }
        });

        if (refresh_token) {
          setCookie({
            name: 'refresh_token',
            value: refresh_token
          });
        }

        localStorageService.setToken({
          key: 'expire_token',
          value: expire_token?.toString()
        });

        fetchUserProfile();
      }
      if (error && message) {
        setErrorMessage(message);
        setIsLoading(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isAxiosError(error)) {
        const errMsg = error.response?.data.message;
        setErrorMessage(errMsg?.email || errMsg?.password || error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      const res = await getUserLogout();
      if (res && !res.error) {
        removeCookie({
          name: 'access_token'
        });
        removeCookie({
          name: 'refresh_token'
        });
        setUserAuth(null);
        setActiveCompany(null);
        router.push('/login');
      } else {
        alert(res.message);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(error?.message);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!userAuth,
        userAuth,
        login,
        isLoading,
        logout,
        errorMessage,
        setErrorMessage,
        setUserAuth,
        activeCompany,
        setActiveCompany,
        isResetCompany,
        setIsResetCompany
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// eslint-disable-next-line react/prop-types
export const ProtectRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const router = useRouter();

  if (isLoading || (!isAuthenticated && router.pathname !== '/login')) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <LoadingIndicatorBox
          color={process.env.LOADING_INDICATOR_COLOR ?? ColorRed.americanRed}
          backdrop
        />
      </div>
    );
  }
  return <>{children}</>;
};
