import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<NavigationMenuInterface> = [
    {
      id: 'activityReport',
      icon: 'notebook',
      title: t('menu:activities'),
      children: [
        {
          id: 'activityReport.view',
          title: t('menu:activityReport'),
          navLink: '/activity/report'
        },
        {
          id: 'activity.view',
          title: t('menu:activityList'),
          navLink: '/activity/list'
        },
        {
          id: 'activityReport.archive',
          title: t('menu:archive'),
          navLink: '/activity/archive'
        }
      ]
    },
    {
      id: 'room',
      icon: 'room',
      title: t('menu:room'),
      children: [
        {
          id: 'room.view',
          title: t('menu:roomList'),
          navLink: '/room'
        }
      ]
    },
    {
      id: 'project',
      icon: 'project',
      title: t('menu:project'),
      children: [
        {
          id: 'project.view',
          title: t('menu:projectList'),
          navLink: '/projects'
        }
      ]
    },
    {
      id: 'announcement',
      icon: 'message',
      title: t('menu:communication'),
      children: [
        {
          id: 'announcement.view',
          title: t('menu:announcements'),
          navLink: '/communication/announcements'
        }
      ]
    },
    {
      id: 'users',
      icon: 'usergroup',
      title: t('menu:accounts'),
      children: [
        {
          id: 'users.view',
          title: t('menu:userList'),
          navLink: '/accounts/users'
        },
        {
          id: 'team.view',
          title: t('menu:teamList'),
          navLink: '/accounts/teams/'
        }
      ]
    }
  ];

  return { navigationConfig };
};
