type AppPlatform = 'rapiku-web' | 'gardaku-web';

export const artworkMapper: Record<AppPlatform, string> = {
  'rapiku-web': '/images/login-artwork-rapiku.svg',
  'gardaku-web': '/images/login-artwork-gardaku.svg'
};

export const logoLoginMapper: Record<AppPlatform, string> = {
  'rapiku-web': '/images/logoRapiKu.svg',
  'gardaku-web': '/images/logoGardaKu.svg'
};

export const logoDashboardMapper: Record<AppPlatform, string> = {
  'rapiku-web': '/images/navbar-logo-rapiku.svg',
  'gardaku-web': '/images/navbar-logo-gardaku.svg'
};

export const icoTitle16: Record<AppPlatform, string> = {
  'rapiku-web': '/icons/favicon-rapiku-16x16.png',
  'gardaku-web': '/icons/favicon-gardaku-16x16.png'
};

export const icoTitle32: Record<AppPlatform, string> = {
  'rapiku-web': '/icons/favicon-rapiku-32x32.png',
  'gardaku-web': '/icons/favicon-gardaku-32x32.png'
};

export const icoTitle96: Record<AppPlatform, string> = {
  'rapiku-web': '/icons/favicon-rapiku-96x96.png',
  'gardaku-web': '/icons/favicon-gardaku-96x96.png'
};
