import { DataMeInterface } from 'interface/UserInterface';
import { createContext, useState } from 'react';

interface UserMeContextInterface {
  userMe: DataMeInterface | null;
  setUserMe: (value: DataMeInterface) => void;
}

export const UserMeContext = createContext<UserMeContextInterface>({
  userMe: null,
  setUserMe: () => undefined
});
export const UserMeContextProvider = UserMeContext.Provider;
export const UserMeContextConsumer = UserMeContext.Consumer;
UserMeContext.displayName = 'User Me Context';

export const AppUserMeContext = ({ children }: { children: React.ReactNode }) => {
  const [userMeContextValue, setUserMeContextValue] = useState<DataMeInterface | null>(null);
  return (
    <UserMeContextProvider
      value={{
        userMe: userMeContextValue,
        setUserMe: setUserMeContextValue
      }}
    >
      {children}
    </UserMeContextProvider>
  );
};
