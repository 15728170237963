import { NavigationMenuInterface } from '@uangcermat/uikit-web/build/src/blocks/Sidebar/Sidebar.type';

export const getGuardedNavigation = ({
  userPermission,
  navConfig
}: {
  userPermission: Array<string>;
  navConfig: Array<NavigationMenuInterface>;
}) => {
  const finalNav: Array<NavigationMenuInterface> = [];
  const userPermissionTmp: Record<string, Array<string>> = {};

  userPermission.map((nav) => {
    const menu = nav.split('.');
    if (menu[0] === 'workforce' && userPermissionTmp[menu[1]] === undefined) {
      userPermissionTmp[menu[1]] = [`${menu[1]}.${menu[2]}`];
    } else {
      if (userPermissionTmp[menu[1]]) {
        userPermissionTmp[menu[1]] = [...userPermissionTmp[menu[1]], `${menu[1]}.${menu[2]}`];
      }
    }
  });

  navConfig.map((menu) => {
    if (userPermissionTmp[menu.id]) {
      const sub: Array<NavigationMenuInterface> = [];
      if (menu?.children) {
        menu.children.map((submenu) => {
          const submenuSplit = submenu.id.split('.');
          if (userPermissionTmp[submenuSplit[0]]?.includes(submenu.id)) {
            sub.push(submenu);
          }
        });
      }
      if (sub.length) finalNav.push({ ...menu, children: sub });
    }
  });
  return finalNav;
};
